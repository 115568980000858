import React, { memo } from 'react'

// Libraries
import {Col, Container, Navbar, Row} from 'react-bootstrap'

// css
import "../../Assets/scss/components/_textbox.scss"
import SocialIcons from "../SocialIcon/SocialIcons";

const iconData = [
    {
        color: "#828282",
        link: "https://www.linkedin.com/company/initivo",
        icon: "fab fa-linkedin"
    }
]
const Address = (props) => {
    return (
        <section id="contact" className="py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px]"
                 style={{backgroundImage: `url(/assets/img/contact_background.jpg)`, backgroundRepeat: "no-repeat"}}>
            <Container>
                <Row className="items-end">
                    <Col className="col-12 col-lg-6 col-md-4 sm:mb-[30px]">
                        <h5 className="font-serif w-[50%] text-darkgray font-medium mb-0 lg:w-[65%] md:w-[95%] xs:w-full">Znajdziesz
                            nas ...</h5>
                    </Col>
                    <Col className="col-12 col-lg-6 col-md-8">
                        <Row>
                            <Col className="col-12 col-sm-6 xs:mb-[30px]">
                                <span className="font-serif block text-darkgray font-medium mb-[10px]">Kontakt</span>
                                <p className="w-[80%] mb-[5px] lg:w-[90%]">Aleja Jana Pawła II 27</p>
                                <p className="w-[80%] mb-[5px] lg:w-[90%]">00-867 Warszawa</p>
                                <span className="block mb-[10px]">KRS: 0001055184</span>
                                <a href="https://www.google.com/maps/place/Initivo+Sp.+z+o.o./@52.2379171,20.9956396,17z/data=!4m14!1m7!3m6!1s0x471ecd074ba8e615:0xff76ada939529cb2!2sInitivo+Sp.+z+o.o.!8m2!3d52.2379171!4d20.9956396!16s%2Fg%2F11vfb3kj_8!3m5!1s0x471ecd074ba8e615:0xff76ada939529cb2!8m2!3d52.2379171!4d20.9956396!16s%2Fg%2F11vfb3kj_8?hl=pl&entry=ttu"
                                   target="_blank" rel="noreferrer"
                                   className="uppercase text-sm text-darkgray font-medium border-b hover:border-fastblue border-black hover:text-fastblue">View
                                    on google map</a>
                                <SocialIcons size="xs" theme="social-icon-style-01"
                                             className="felx justify-start md:justify-center"
                                             iconColor={props.theme === "dark" ? "light" : "dark"} data={iconData}/>
                            </Col>
                            <Col className="col-12 col-sm-6">
                                <span className="font-serif block text-white font-medium mb-[10px]">-</span>
                                <p className="w-[80%] mb-[2px] lg:w-[90%]">Mail: <a
                                    href="mailto:office@initivo.com">office@initivo.com</a></p>
                                <span className="block mb-[10px]">Tel: (+48) 692 493 658</span>
                            </Col>
                            <span
                                className="font-serif font-light text-gray-500 text-xs mb-[30px] text-justify inline-grid sm:mb-[5px]">
                    <br></br>INITIVO Sp. z o.o. z siedzibą w Warszawie (00-867) przy Alei Jana Pawła II 27, zarejestrowana w Sądzie Rejonowym dla M. St. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Gospodarczego; KRS 0001055184, REGON: 526243630; NIP: 5273073325</span>
                            <span
                                className="font-serif font-light text-gray-500 text-xs mb-[30px] text-justify inline-grid sm:mb-[5px]">
                    Podmiot wpisany do rejestru podmiotów prowadzących agencję zatrudnienia pod numerem 30537
                  </span>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>)
}

export default memo(Address)
