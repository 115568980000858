import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import {Routes, Route, useLocation, Link} from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop"
import {Footer} from "./Components/Footers/Footer";
import {Col, Container, Row} from "react-bootstrap";
import Privacy from "./Pages/Privacy";
import CookieBanner from "react-cookie-banner";

// Pages
const HomePage = lazy(() => import("./Pages/HomePage"))



function App() {
  const styles = {
    banner: {
      fontFamily: 'Source Sans Pro',
      height: 57,
      background: 'rgba(52, 64, 81, 0.88) url(/cookie.png) 20px 50% no-repeat',
      backgroundSize: '30px 30px',
      backgroundColor: '',
      fontSize: '15px',
      fontWeight: 600,
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      zIndex: 9999,
    },
    button: {
      border: '1px solid white',
      borderRadius: 4,
      width: 66,
      height: 32,
      lineHeight: '32px',
      background: 'transparent',
      color: 'white',
      fontSize: '14px',
      fontWeight: 600,
      opacity: 1,
      right: 20,
      marginTop: -18
    },
    message: {
      display: 'block',
      padding: '9px 67px',
      lineHeight: 1.3,
      textAlign: 'left',
      marginRight: 244,
      color: 'white'
    },
    link: {
      textDecoration: 'none',
      fontWeight: 'bold'
    }
  }

  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false
  })
  const location = useLocation();
  const [dismissOnScroll, seTdismissOnScroll] = useState(false);
  const [dismissOnClick, setDismissOnClick] = useState(false);
  const [accepted, setAccepted] = useState(false);

  // RetinaJS
  useEffect(() => {
    window.addEventListener('load', retina(document.querySelectorAll('img')));
  }, [])

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then(module => {
        module.SetHeaderMenuPos()
        module.setDocumentFullHeight()
      })
    }, 1000);
  }, [location])

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <CookieBanner
          styles={styles}
          message={"initivo.com wykorzystuje wyłącznie techniczne (niezbędne) pliki cookies, dzięki którym serwis działa poprawnie. Nie są one używane do celów marketingowych ani analitycznych. Kontynuując korzystanie z witryny, wyrażasz zgodę na ich użycie."}
          // link={<a href='http://nocookielaw.com/'>More information on our use of cookies</a>}
          buttonMessage='OK!'
          dismissOnScroll={dismissOnScroll}
          dismissOnClick={dismissOnClick}
          onAccept={() => setAccepted(true)}
      />
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<></>}>
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/privacyPolicy" element={<Privacy />} />
                  <Route path="*" element={<HomePage/>} />
                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
      </div>
      {/* Footer Start */}
      <Footer theme="dark" className="text-slateblue bg-[#262b35] footer-style-03">
        <div className="py-[35px] border-t border-[#ffffff1a]">
          <Container>
            <Row>
              <Col md={3} className="sm:mb-[20px]">
                <Link aria-label="link" to="/" className="sm:flex sm:justify-center">
                  <img src="/assets/img/logo.svg" alt="logo" width="111" height="36" />
                </Link>
              </Col>
              <Col md={6} className="flex justify-center sm:mb-[20px]">
                <p className="mb-0 xs:text-center">&copy; {new Date().getFullYear()} INITIVO is Proudly Powered by <a aria-label="dercodo.io" rel="noreferrer" className="text-themecolor font-sans underline underline-offset-4 font-normal text-base" rel="noreferrer" target="_blank" href="https://dercode.io">DerCode</a></p>
              </Col>
              <Col md={3}>
                {/*<SocialIcons size="xs" theme="social-icon-style-01" className="felx justify-end md:justify-center" iconColor={props.theme === "dark" ? "light" : "dark"} data={iconData} />*/}
              </Col>
            </Row>
          </Container>
        </div>
      </Footer>
      {/* Footer End */}

    </GlobalContext.Provider>
  )
}

export default App;
