import React from 'react'

// Libraries
import { Col, Container, Navbar, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

// Components
import { Header, HeaderCart, HeaderLanguage, HeaderNav, Menu, SearchBar } from '../Components/Header/Header'
import Lists from "../Components/Lists/Lists"
import Address from "../Components/Footers/Address";


const PointThree = [
    {
        content: "zgodnie z przepisami dotyczącymi ochrony danych osobowych,",
    },
    {
        content: "zgodnie z wdrożoną Polityką Prywatności,",
    },
    {
        content: "w zakresie i celu niezbędnym do nawiązania, ukształtowania treści Umowy, zmiany bądź jej rozwiązania oraz prawidłowej realizacji Usług świadczonych drogą elektroniczną,",
    },
    {
        content: "w zakresie i celu niezbędnym do wypełnienia uzasadnionych interesów (prawnie usprawiedliwionych celów), a przetwarzanie nie narusza praw i wolności osoby, której dane dotyczą,",
    },
    {
        content: "w zakresie i celu zgodnym ze zgodą wyrażoną przez Ciebie jeśli przesłałeś nam swoje dokumenty aplikacyjne (CV).",
    },
]
const PointEleven = [
    {
        content: "wybranym klientom (pracodawcom), których zapotrzebowanie kadrowe będzie odpowiadać Pana/Pani doświadczeniu i wykształceniu,",
    },
    {
        content: "podmiotom zajmującym się przechowywaniem danych osobowych dla Administratora.",
    }
]


const Privacy = (props) => {
  return (
      <div style={props.style}>
          {/* Header Start */}
          <Header topSpace={{desktop: true}} type="reverse-scroll">
              <HeaderNav theme="light" expand="lg" className="py-[0px] lg:px-[15px] md:px-0" containerClass="sm:px-0">
                  <Col className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0">
                      <Link aria-label="header logo" className="flex items-center" to="/">
                          <Navbar.Brand className="inline-block p-0 m-0">
                              <img className="default-logo" width="111" height="55" loading="lazy" src='/assets/img/logo.svg' data-rjs='/assets/img/logo.svg' alt='logo' />
                              <img className="alt-logo" width="111" height="36" loading="lazy" src='/assets/img/logo.svg' data-rjs='/assets/img/logo.svg' alt='logo' />
                              <img className="mobile-logo" width="111" height="36" style={{padding: '5px'}} loading="lazy" src='/assets/img/logo.svg' data-rjs='/assets/img/logo.svg' alt='logo' />
                          </Navbar.Brand>
                      </Link>
                  </Col>
                  <Navbar.Toggle className="order-last md:ml-[25px] sm:ml-[17px]">
                      <span className="navbar-toggler-line"></span>
                      <span className="navbar-toggler-line"></span>
                      <span className="navbar-toggler-line"></span>
                      <span className="navbar-toggler-line"></span>
                  </Navbar.Toggle>
                  <Navbar.Collapse className="col-auto col-lg-8 px-lg-0 mx-auto justify-center">
                      <ul className="navbar-nav alt-font text-black">
                          <li className="nav-item">
                              <Link className="nav-link" to="https://initivo.com#flow"> Jak pracujemy
                              </Link>
                          </li>
                          <li className="nav-item">
                              <Link className="nav-link" to="https://initivo.com#services"> Specjalizacje
                              </Link>
                          </li>
                          <li className="nav-item">
                              <Link className="nav-link" to="https://initivo.com#for-candidate"> Dla Kandydatów
                              </Link>
                          </li>
                          <li className="nav-item">
                              <Link className="nav-link" to="https://initivo.com#contact"> Kontakt
                              </Link>
                          </li>
                      </ul>
                  </Navbar.Collapse>
              </HeaderNav>
          </Header>
          <Navbar.Collapse collapseonselect="true" className="col-auto menu-order justify-end px-0">
              <Menu {...props} />
          </Navbar.Collapse>
          {/* Header End */}

          {/* Section Start */}
          <section className="py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]">
              <Container>
                  <Row className="items-center justify-center">
                      <Col lg={9} md={12} className="col-12">
                          <h6 className="font-serif text-darkgray font-medium -tracking-[1px]">Polityka prywatności</h6>
                          <li type="1" className="mb-[5px] md:mb-[5px] sm:mb-[5px]">Administratorem Pani/a danych
                              osobowych jest INITIVO Sp. z o.o. z siedzibą w Warszawie (00-867) przy Alei Jana Pawła II
                              27, tel. 692 493 658, e-mail: office@initivo.com, zarejestrowaną w Sądzie Rejonowym dla M.
                              St. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Gospodarczego; KRS
                              0001055184, REGON: 52624363000000 ; NIP: 5273073325
                          </li>
                          <li type="1" className="mb-[5px] md:mb-[5px] sm:mb-[5px]">Szanujemy prawo do prywatności i
                              dbamy o bezpieczeństwo danych.
                          </li>
                          <li type="1" className="mb-[5px] md:mb-[5px] sm:mb-[5px]">Dane osobowe przetwarzane są:
                          </li>
                          <Lists className="mb-[5px] md:mb-[5px] sm:mb-[5px]" theme="list-style-06" data={PointThree} animationDelay={0}/>
                          <li type="1" className="mb-[5px] md:mb-[5px] sm:mb-[5px]">Posiada Pani/Pan prawo dostępu do danych, ich poprawiania, żądania ich usunięcia, a także prawo ograniczenia przetwarzania, przenoszenia danych, wniesienia sprzeciwu co do przetwarzania danych osobowych i prawo wniesienia skargi do organu nadzorczego, jeżeli dane są przetwarzane niezgodnie z wymogami prawnymi. Usunięcie danych osobowych może nastąpić na skutek cofnięcia zgody bądź wniesienia prawnie dopuszczalnego sprzeciwu na przetwarzanie danych osobowych.</li>

                          <li type="1" className="mb-[5px] md:mb-[5px] sm:mb-[5px]">Kontakt z osobą nadzorującą przetwarzanie danych osobowych w organizacji jest możliwy drogą elektroniczną pod adresem e-mail: office@initivo.com</li>
                          <li type="1" className="mb-[5px] md:mb-[5px] sm:mb-[5px]">Zastrzegamy sobie prawo do przetwarzania Twoich danych po rozwiązaniu Umowy lub cofnięciu zgody tylko w zakresie na potrzeby dochodzenia ewentualnych roszczeń przed sądem lub jeżeli przepisy krajowe albo unijne bądź prawa międzynarodowego obligują nas do retencji danych.</li>
                          <li type="1" className="mb-[5px] md:mb-[5px] sm:mb-[5px]">Podanie danych jest dobrowolne, a konsekwencją ich niepodania będzie nieuwzględnienie Pani/a kandydatury w toku prowadzonych rekrutacji.</li>
                          <li type="1" className="mb-[5px] md:mb-[5px] sm:mb-[5px]">Dane osobowe przetwarzają osoby wyłącznie upoważnione przez nas albo przetwarzający, z którymi ściśle współpracujemy.</li>
                          <li type="1" className="mb-[5px] md:mb-[5px] sm:mb-[5px]">Posiada Pani/Pan prawo do cofnięcia zgody w dowolnym momencie. Pozostanie to jednak bez wpływu na zgodność z prawem przetwarzania danych, którego dokonano przed jej cofnięciem</li>
                          <li type="1" className="mb-[5px] md:mb-[5px] sm:mb-[5px]">Administrator nie udostępnia danych osobowych innym podmiotom aniżeli upoważnionym na podstawie właściwych przepisów prawa.</li>
                          <li type="1" className="mb-[5px] md:mb-[5px] sm:mb-[5px]">Administrator może przekazywać Pani/a dane:</li>
                          <Lists className="mb-[5px] md:mb-[5px] sm:mb-[5px]" theme="list-style-06" data={PointEleven} animationDelay={0}/>
                          <li type="1" className="mb-[5px] md:mb-[5px] sm:mb-[5px]">Nie podlega Pan/Pani decyzji, która opiera się wyłącznie na zautomatyzowanym przetwarzaniu, w tym profilowaniu, i wywołuje wobec Pana/i skutki prawne lub w podobny sposób istotnie na Pana/ią wpływa.</li>
                          <li type="1" className="mb-[5px] md:mb-[5px] sm:mb-[5px]">Administrator nie planuje przekazywać Pana/Pani danych osobowych odbiorcom spoza EOG, czyli do państw trzecich.</li>
                          <li type="1" className="mb-[5px] md:mb-[5px] sm:mb-[5px]">Pana/Pani dane osobowe będą przetwarzane przez czas prowadzenia działalności przez Administratora, jednak nie dłużej niż do czasu cofnięcia przeze Pana/Pani zgody na przetwarzanie danych.</li>
                          <p className="mb-0">&nbsp;</p>
                      </Col>
                  </Row>
              </Container>
          </section>
          {/* Section End */}
          <Address/>
      </div>
  )
}

export default Privacy
